import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ToastMama(){
  const notify = () => toast("Wow so easy!");

  useEffect(()=>{
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          // ...
          console.log(user);
        } else {
          // User is signed out
          // ...
          console.log("user is logged out")
        }
      });
     
}, [])

  return (
    <div>
      <button onClick={notify}>Notify!</button>      
    </div>
  );
}
export default ToastMama;