import { create } from "zustand";
import axios from "../api/axios";

export const useTestStore = create((set, get) => ({
  items: [],
  getItems: async () => {

    const response = await axios.get("meals.json");

    const loadedItems = [];
    for (const key in response.data) {
      loadedItems.push({
        id: key,
        description: response.data[key].description,        
      });
    }

    set((state) => ({
      items: loadedItems,
    }));
  },
}));
