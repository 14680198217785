import { UNSAFE_enhanceManualRouteObjects } from "react-router-dom";
import { create } from "zustand";
import { useAuthStore } from "./auth-store";
import { useCatsStore } from "./cats-store";

export const useItemStore = create((set, get) => ({
  items: [],
  isLoading: true,
  getItems: async () => {
    const res = await fetch(
      "https://react-http-29978-default-rtdb.firebaseio.com/foods.json"
    );
    const responseData = await res.json();

    const loadedItems = [];
    for (const key in responseData) {
      loadedItems.push({
        id: key,
        image: responseData[key].image,
        title: responseData[key].title,
        description: responseData[key].description,
        price: +responseData[key].price,
        category: responseData[key].category,
      });
    }

    set((state) => ({
      ...state,
      items: loadedItems,
      isLoading: false,
    }));
  },
  getItem: async (itemId) => {
    // const res = await fetch(
    //   `https://react-http-29978-default-rtdb.firebaseio.com/foods/${itemId}.json`
    // );
    // const responseData = await res.json();
    /*const item = {
      id: key,
      image: responseData[key].image,
      title: responseData[key].title,
      description: responseData[key].description,
      price: +responseData[key].price,
    };

    return item;*/
  },
  filteredItems: (selectedCat) => {
    const items = [...get().items];
    if (selectedCat === null) {
      return items;
    }
    const result = items.filter((item) => item.category === selectedCat);
    return result;
  },
  addItem: async (item) => {
    const auth = useAuthStore.getState().auth;
    const url = `https://react-http-29978-default-rtdb.firebaseio.com/foods.json?auth=${auth.token}`;

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        description: item.description,
        image: item.image,
        price: +item.price,
        title: item.title,
        category: item.category,
      }),
    });

    if (!res.ok) {
      throw new Error("Can't Create item");
    }

    const responseData = await res.json();

    set((state) => ({
      ...state,
      items: [
        ...state.items,
        {
          id: responseData.name,
          image: item.image,
          title: item.title,
          description: item.description,
          price: +item.price,
          category: item.category,
        },
      ],
    }));
  },
  updateItem: async (itemId, item) => {
    const auth = useAuthStore.getState().auth;
    const url = `https://react-http-29978-default-rtdb.firebaseio.com/foods/${itemId}.json?auth=${auth.token}`;    

    const res = await fetch(url, {
      method: "PATCH",
      body: JSON.stringify({
        description: item.description,
        image: item.image,
        price: +item.price,
        title: item.title,
        category: item.category,
      }),
    });

    if (!res.ok) {
      throw new Error("Can't update item");
    }

    const items = [...get().items];
    const objIndex = items.findIndex((obj) => obj.id === itemId);
    items[objIndex].description = item.description;
    items[objIndex].image = item.image;
    items[objIndex].price = +item.price;
    items[objIndex].title = item.title;
    items[objIndex].category = item.category;

    set((state) => ({
      ...state,
      items: items,
    }));
  },
  deleteItem: async (itemId) => {
    const auth = useAuthStore.getState().auth;

    const res = await fetch(
      `https://react-http-29978-default-rtdb.firebaseio.com/foods/${itemId}.json?auth=${auth.token}`,
      { method: "DELETE" }
    );

    if (!res.ok) {
      throw new Error("Can't delete item");
    }

    set((state) => ({
      ...state,
      items: state.items.filter((item) => item.id !== itemId),
    }));
  },
}));

// start fetching initially
useItemStore.getState().getItems();
