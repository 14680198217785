import React, { useEffect, useMemo, useRef, useState } from "react";
import { useItemStore } from "../../store/items-store";
import Item from "./Item";
import { AnimatePresence, motion } from "framer-motion";
import { useCatsStore } from "../../store/cats-store";

const containerAnimation = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const itemAnimate = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const ListItems = (props) => {
  // //const [isLoading, setIsLoading] = useState(true);
  // const [isError, setIsError] = useState();

  // //const items = useItemStore((state) => state.items);
  // const filteredItems = useItemStore((state) => state.filteredItems);
  // const selectedCat = useCatsStore((state) => state.selectedCat);
  // const isLoading = useItemStore((state) => state.isLoading);

  const visibleItems = useMemo(() => {
    if (props.filter === null) {
      return props.items;
    }
    const result = props.items.filter((item) => item.category === props.filter);
    return result;
  }, [props.filter, props.items]);

  // if (isLoading) {
  //   return (
  //     <h1 className="text-center text-2xl font-bold text-gray-800">
  //       Loading...
  //     </h1>
  //   );
  // }

  // if (isError) {
  //   return (
  //     <h1 className="text-center text-2xl font-bold text-gray-800">Error</h1>
  //   );
  // }

  return (
    <motion.div
      className="mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
    >
      <AnimatePresence mode="popLayout">
        {visibleItems.map((item) => (
          <motion.div variants={itemAnimate} key={item.id}>
            <Item item={item} />
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default ListItems;
