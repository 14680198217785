import { create } from "zustand";
import axios from "../api/axios";
import { useAuthStore } from "./auth-store";
import { useItemStore } from "./items-store";

export const useCatsStore = create((set, get) => ({
  cats: [],
  isLoading: true,
  selectedCat: null,
  selectCategory: (categoryId) => {
    set((state) => ({
      ...state,
      selectedCat: categoryId,
    }));
  },
  getCats: async () => {
    const response = await axios.get("foods_cats.json");

    const loadedCats = [];
    for (const key in response.data) {
      loadedCats.push({
        id: key,
        name: response.data[key].name,
      });
    }

    set((state) => ({
      cats: loadedCats,
      isLoading: false,
    }));
  },
  getCat: async (itemId) => {
    const response = await axios.get(`foods_cats/${itemId}.json`);
    return response.data;
  },
  addCat: async (cat) => {
    const auth = useAuthStore.getState().auth;

    try {
      const response = await axios.post(`foods_cats.json?auth=${auth.token}`, {
        name: cat.name,
      });

      set((state) => ({
        ...state,
        cats: [
          ...state.cats,
          {
            id: response.data.name,
            name: cat.name,
          },
        ],
      }));
    } catch (error) {
      throw new Error("Can't Create item");
    }
  },
  deleteCat: async (itemId) => {
    const auth = useAuthStore.getState().auth;

    const items = useItemStore.getState().items;
    console.log(items);
    const found = items.find((item) => item.category === itemId);
    console.log(found);

    if (found) {
      throw new Error("The category is already in use");
    }

    try {
      await axios.delete(`foods_cats/${itemId}.json?auth=${auth.token}`);

      set((state) => ({
        ...state,
        cats: state.cats.filter((cat) => cat.id !== itemId),
      }));
    } catch (error) {
      throw new Error(error.message);
    }
  },
  editCat: async (itemId, item) => {
    const auth = useAuthStore.getState().auth;

    try {
      await axios.patch(`foods_cats/${itemId}.json?auth=${auth.token}`, {
        name: item.name,
      });
    } catch (error) {
      throw new Error("Can't update item");
    }

    const items = [...get().cats];
    const objIndex = items.findIndex((obj) => obj.id === itemId);
    items[objIndex].name = item.name;

    set((state) => ({
      ...state,
      cats: items,
    }));
  },
}));

// start fetching initially
useCatsStore.getState().getCats();
