import React, { useEffect, useState } from "react";
import { json, useLoaderData, useParams, useRouteLoaderData } from "react-router-dom";
import FormItem from "../components/items/FormItem";
import { useCatsStore } from "../store/cats-store";
import { useItemStore } from "../store/items-store";

const EditItemPage = () => {  
  const data = useLoaderData();  

  return <FormItem item={data} method="patch" />;
};

export default EditItemPage;

export async function loader({ request, params }) {
  const itemId = params.id;

  const data = await useCatsStore.getState().getCat(itemId);
  const response = await fetch(
    `https://react-http-29978-default-rtdb.firebaseio.com/foods/${itemId}.json`
  );  
  if (!response.ok) {
    throw json(
      { message: "Could not fetch details for selected event." },
      { status: 500 }
    );
  } else {
    const resData = await response.json();    
    
    return {
        id: itemId,
        description: resData.description,
        price: resData.price,
        title: resData.title,
        image: resData.image,
    };
  }
}
