import React from "react";
import ListCats from "../components/categorias/ListCats";

const CatsHomePage = () => {
  return (
    <>
      <section className="py-10 bg-gray-100">
        <ListCats />
      </section>
    </>
  );
};

export default CatsHomePage;
