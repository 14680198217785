import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useTestStore } from "../store/test-store";

const AxiosTest = () => {
  const items = useTestStore((state) => state.items);
  const data = useLoaderData();
  console.log(data);

  return (
    <div>
      <h1>MAMA</h1>
      <h1>{JSON.stringify(items?.length)}</h1>
      {items?.length > 0 ? (
        <ul>
          {items.map((item) => (
            <li key={item.id}>{item?.description}</li>
          ))}
        </ul>
      ) : (
        <p>no mamamama</p>
      )}
    </div>
  );
};

export default AxiosTest;

export async function loader({ request, params }) {
  const getItems = useTestStore.getState().getItems;  
  await getItems();
  return null;
}
