import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ToastMama from "./components/ui/Toast";
import "./App.css";
import EditItemPage, { loader as eventLoader } from "./pages/EditItemPage";

import { HomePage } from "./pages/HomePage";
import LoginLayout from "./pages/layouts/LoginLayout";
import LoginPage from "./pages/LoginPage";
import NewItemPage from "./pages/NewItemPage";
import RootLayout from "./pages/RootLayout";
import { action as logoutAction } from "./pages/Logout";
import AxiosTest, { loader as testLoader } from "./pages/AxiosTest";
import CatsHomePage from "./pages/CatsHomePage";
import EditCatsPage, { loader as catsLoader } from "./pages/EditCatsPage";
import AdminLayout from "./pages/layouts/AdminLayout";
import { AdminPage } from "./pages/AdminHome";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    //errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },      
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    //errorElement: <ErrorPage />,
    children: [      
      { index: true, element: <AdminPage /> },     
      { path: "newItem", element: <NewItemPage /> },
      { path: "editItem/:id", element: <EditItemPage />, loader: eventLoader },
      { path: "mama", element: <ToastMama /> },
      { path: "listCats", element: <CatsHomePage />},
      { path: "edit", element: <EditCatsPage />, loader: catsLoader },
    ],
  },
  {
    path: "/login",
    element: <LoginLayout />,
    children: [{ path: "/login", element: <LoginPage /> }],
  },
  { path: "/test", element: <AxiosTest />, loader: testLoader },
  {
    path: "/logout",
    action: logoutAction,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
