import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useItemStore } from "../../store/items-store";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useCatsStore } from "../../store/cats-store";
import { useAuthStore } from "../../store/auth-store";

const Item = (props) => {
  const [imageLoaded, setImageLoaded] = useState(
    "https://www.kindpng.com/picc/m/285-2856629_placeholder-highlights-for-instagram-camra-hd-png-download.png"
  );
  const deleteItem = useItemStore((state) => state.deleteItem);
  const cats = useCatsStore((state) => state.cats);
  const navigate = useNavigate();
  const auth = useAuthStore((state) => state.auth);

  useEffect(() => {
    const storage = getStorage();
    const pathReference = ref(storage, "files/" + props.item.image);
    const downloadURL = async () => {
      const data = await getDownloadURL(pathReference);
      setImageLoaded(data);
    };

    downloadURL();
  }, [props.item.image]);

  async function deleteHandler(id) {
    const proceed = window.confirm("Are you sure?");
    if (proceed) {
      try {
        await deleteItem(id);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      // Create a reference to the file to delete
      const storage = getStorage();
      const desertRef = ref(storage, "files/" + props.item.image);

      // Delete the file
      await deleteObject(desertRef);
    }
  }

  function nameCat(category) {
    const index = cats.findIndex((element) => element.id === category);
    return cats[index]?.name;
  }

  return (
    <>
      <article className="rounded-xl bg-white p-3 shadow-white hover:shadow-xl hover:transform hover:scale-105 duration-300 ">
        <div className="relative flex items-end overflow-hidden rounded-xl">
          <div className="w-full">
            <img
              src={imageLoaded}
              alt={props.item.title}
              className="object-cover h-48 w-96"
            />
          </div>
        </div>

        <div className="mt-1 p-2">
          <div className="mt-3 flex items-end justify-between">
            <h2 className="text-red-600">{props.item.title}</h2>
            <p className="px-4 py-2 bg-red-800 hover:bg-red-500 text-white text-sm font-medium rounded-full">
              {nameCat(props.item.category)}
            </p>
          </div>
          <p className="mt-1 text-sm text-black">{props.item.description}</p>

          <div className="mt-3 flex items-end justify-between">
            <p className="text-lg font-bold text-red-600">
              $ {props.item.price.toFixed(2)}
            </p>

            {auth && auth.token && (
              <div className="flex items-end justify-between w-20">
                <Link
                  className="inline-block align-baseline font-bold text-sm text-red-600 hover:text-red-500"
                  to={`editItem/${props.item.id}`}
                >
                  Edit
                </Link>
                <button
                  className="inline-block align-baseline font-bold text-sm text-red-500 hover:text-red-800"
                  onClick={() => {
                    deleteHandler(props.item.id);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </article>
    </>
  );
};

export default Item;
