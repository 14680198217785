import { Field, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { storage } from "../../firebase";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";
import { useItemStore } from "../../store/items-store";
import { toast } from "react-toastify";
import MultiSelect from "../ui/MultiSelect";
import { useCatsStore } from "../../store/cats-store";

const ItemSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  description: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  image: Yup.mixed(),
  price: Yup.number("Must be a number").required("Required"),
});

const ItemSchemaUpdate = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  description: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  image: Yup.mixed().notRequired(),
  price: Yup.number("Must be a number").required("Required"),
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const FormItem = (props) => {
  const [isSentForm, setIsSentForm] = useState(false);
  const navigate = useNavigate();
  const addItem = useItemStore((state) => state.addItem);
  const updateItem = useItemStore((state) => state.updateItem);
  const cats = useCatsStore((state) => state.cats);
  return (
    <Formik
      initialValues={{
        title: props.item ? props.item.title : "",
        description: props.item ? props.item.description : "",
        image: null,
        price: props.item ? +props.item.price : "",
      }}
      validationSchema={props.item ? ItemSchemaUpdate : ItemSchema}
      onSubmit={async (values, { resetForm }) => {
        setIsSentForm(true);

        if (values.image instanceof File) {
          const resizedImage = await resizeFile(values.image);

          // same shape as initial values
          const ext = values.image.name.split(".").pop();
          const fileName = uuidv4() + "." + ext;
          const storageRef = ref(storage, `files/${fileName}`);
          //storageRef.makePublic();
          //const uploadTask = uploadBytesResumable(storageRef, values.image);

          // Create file metadata including the content type
          const metadata = {
            cacheControl: "public,max-age=300",
            contentType: "image/" + ext,
          };

          uploadString(storageRef, resizedImage, "data_url", metadata).then(
            (snapshot) => {
              const fetchForm = async () => {                
                try {
                  await addItem({
                    description: values.description,
                    image: fileName,
                    price: values.price,
                    title: values.title,
                    category: values.category,
                  });
                } catch (error) {
                  toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                  });
                }
              };

              fetchForm();
              setIsSentForm(false);
              navigate("/admin");
            }
          );
        } else {
          const fetchForm = async () => {            
            try {
              await updateItem(props.item.id, {
                description: values.description,
                image: props.item.image,
                price: values.price,
                title: values.title,
                category: values.category,
              });
            } catch (error) {
              toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
            }
          };

          fetchForm();
          setIsSentForm(false);
          navigate("/admin");
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="rounded-t-lg overflow-hidden  px-3 py-10 flex justify-center">
          <form onSubmit={handleSubmit} className="w-full max-w-lg">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  placeholder="Jhon"
                />
                {errors.title && touched.title && (
                  <p className="text-red-500 text-xs italic">{errors.title}</p>
                )}
              </div>
            </div>
            <div className="mb-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="category"
              >
                Category
              </label>
              <Field
                label="category"
                name="category"
                id="category"
                placeholder="Category"
                isMulti={false}
                component={MultiSelect}
                options={cats.map((cat) => {
                  return { value: cat.id, label: cat.name };
                })}
              />
            </div>
            <div>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                type="text"
                rows="4"
                name="description"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                placeholder="Doe"
              />
              {errors.description && touched.description && (
                <p className="text-red-500 text-xs italic">
                  {errors.description}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="image"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                Image
              </label>
              <input
                id="file"
                name="image"
                type="file"
                onChange={(event) => {
                  setFieldValue("image", event.currentTarget.files[0]);
                }}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              />
              {/* <Thumb file={values.image} />  */}
              {errors.image && touched.image && (
                <p className="text-red-500 text-xs italic">{errors.image}</p>
              )}
            </div>
            <div>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="price"
              >
                Price
              </label>
              <input
                type="text"
                name="price"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
              />
              {errors.price && touched.price && (
                <p className="text-red-500 text-xs italic">{errors.price}</p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isSentForm}
              >
                {isSentForm ? "Sending..." : "Save"}
              </button>
              <Link
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                to="/admin"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default FormItem;
