import React from "react";
import { useLoaderData } from "react-router-dom";
import FormCat from "../components/categorias/FormCat";
import { useCatsStore } from "../store/cats-store";

const EditCatsPage = () => {
  const data = useLoaderData();

  return <FormCat item={data} method="patch" />;
};

export default EditCatsPage;

export async function loader({ request, params }) {
    const itemId = params.id;
  
    const data = await useCatsStore.getState().getCat(itemId);
    return data;
    
    
    // if (!response.ok) {
    //   throw json(
    //     { message: "Could not fetch details for selected event." },
    //     { status: 500 }
    //   );
    // } else {
    //   const resData = await response.json();    
      
    //   return {
    //       id: itemId,
    //       description: resData.description,
    //       price: resData.price,
    //       title: resData.title,
    //       image: resData.image,
    //   };
    // }
  }
