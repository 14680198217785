import React, { Fragment } from "react";
import classes from "./Modal.module.css";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

const modalAnimate = {
  hidden: { x: 250, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <motion.div
      className="fixed top-1/3 left-1/3 w-1/3 z-40"
      variants={modalAnimate}
      initial="hidden"
      animate="visible"
    >
      {props.children}
    </motion.div>
  );
};

const portalEl = document.getElementById("overlays");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalEl)}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalEl
      )}
    </Fragment>
  );
};

export default Modal;
