import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/auth-store";
import * as Yup from "yup";
import SubmitButton from "../components/ui/SubmitButton";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const LoginPage = () => {
  const login = useAuthStore((state) => state.login);
  const auth = useAuthStore((state) => state.auth);
  const [isError, setIsError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && auth.token) {
      navigate("/");
    }
  });

  async function submit(email, password) {
    setIsSending(true);
    const isLogged = await login(
      email,
      password
    );
    if (isLogged) {
      navigate("/admin");
    } else {
      setIsError(true);
    }
    setIsSending(false);
  }

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { resetForm }) => {
            submit(values.email, values.password);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
              <h3 className="text-2xl font-bold text-center">
                Login to your account
              </h3>
              {!isSending && isError && (
                <p className="text-red-500 text-center mt-2">
                  Invalid credentials
                </p>
              )}
              <form action="" onSubmit={handleSubmit}>
                <div className="mt-4">
                  <div>
                    <label className="block" htmlFor="email">
                      Email
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      type="text"
                      placeholder="Email"
                      className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                    {errors.email && touched.email && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="block" htmlFor="password">
                      Password
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      name="password"
                      type="password"
                      placeholder="Password"
                      className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                    {errors.password && touched.password && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  <div className="flex items-baseline justify-between">
                    <SubmitButton isLoading={isSending} loadingText="Loading...">Login</SubmitButton>
                    <a
                      href="#"
                      className="text-sm text-blue-600 hover:underline"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default LoginPage;
