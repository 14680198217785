import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { create } from "zustand";
import { firebaseApp } from "../firebase";
import { persist, createJSONStorage } from "zustand/middleware";

const firebaseAppConfig = firebaseApp;
const auth = getAuth(firebaseAppConfig);

export const useAuthStore = create(
  persist(
    (set, get) => ({
      auth: null,
      login: async (email, password) => {
        try {
          const res = await signInWithEmailAndPassword(auth, email, password);
          console.log(res._tokenResponse.refreshToken);
          console.log(res._tokenResponse.expiresIn);
          console.log(res.user.accessToken);
          set((state) => ({
            auth: {
              token: res.user.accessToken,
              uid: res.user.uid,
              expiresIn: res._tokenResponse.expiresIn,
              refreshToken: res._tokenResponse.refreshToken,
            },
          }));

          // setTimeout(function () {
          //   get().logout();
          //   console.log("BYTES");
          // }, res._tokenResponse.refreshToken * 1000 * 60);

          return true;
        } catch (error) {
          return false;
        }
      },
      logout: () => {
        set((state) => ({
          auth: null,
        }));
        return true;
      },
      authStatus: () => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            // ...
          } else {
            // User is signed out
            // ...
          }
        });
      },
    }),
    {
      name: "food-storage", // unique name
      //storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
