import React from "react";
import { motion } from "framer-motion";
import logo from "../../logo/REDWOOD.png";

const Logo = () => {
  return (
    <>
      <div className="pt-12 bg-black">
        <motion.div          
          initial={{ opacity: 0.1, scale: 0.25 }}
          transition={{ duration: 5, delay: 0.3, ease: [0.5, 0.71, 1, 1.5] }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <div className="flex justify-center w-full">
            <img src={logo} alt="redwood" className="w-64" />
          </div>
        </motion.div>
      </div>
      
    </>
  );
};

export default Logo;
