import React, { useState } from "react";
import CatFilter from "../components/categorias/CatFilter";
import ListItems from "../components/items/ListItems";
import { useCatsStore } from "../store/cats-store";
import { useItemStore } from "../store/items-store";

export const HomePage = () => {
  const items = useItemStore((state) => state.items);
  const cats = useCatsStore((state) => state.cats);
  const [selectedCat, setSelectedCat] = useState(null);

  const selectedCatHandler = (category) => {
    setSelectedCat(category);
  };

  return (
    <>
      <section className="py-10 bg-black">
        <CatFilter cats={cats} onSelect={selectedCatHandler} />
        <ListItems items={items} filter={selectedCat} />
      </section>
    </>
  );
};
