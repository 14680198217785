import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainNavigation from "../../components/layout/MainNavigation";
import { useAuthStore } from "../../store/auth-store";

const AdminLayout = () => {
  const auth = useAuthStore((state) => state.auth);

  const navigate = useNavigate();
  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth]);

  return (
    <>
      <MainNavigation />
      <main className="mt-32">
        <Outlet />
      </main>
      <ToastContainer />
    </>
  );
};

export default AdminLayout;
