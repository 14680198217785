import React, { useState } from "react";
import { json } from "react-router-dom";
import { toast } from "react-toastify";
import { useCatsStore } from "../../store/cats-store";
import Modal from "../ui/Modal";
import FormCat from "./FormCat";

const ListCats = () => {
  const cats = useCatsStore((state) => state.cats);
  const deleteCatStore = useCatsStore((state) => state.deleteCat);
  const isLoading = useCatsStore((state) => state.isLoading);
  const [isError, setIsError] = useState();
  const [showModal, setShowModal] = useState();
  const [editCategory, setEditCategory] = useState();

  if (isLoading) {
    return (
      <h1 className="text-center text-2xl font-bold text-gray-800">
        Loading...
      </h1>
    );
  }

  if (isError) {
    return (
      <h1 className="text-center text-2xl font-bold text-gray-800">Error</h1>
    );
  }

  const showModalHandler = () => {
    setShowModal(true);
    setEditCategory(null);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const deleteCat = async(id) => {
    try {
      await deleteCatStore(id);
      toast.success("Category eliminated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const editCat = (item) => {
    setShowModal(true);
    setEditCategory(item);
  };

  return (
    <>
      <div className="flex justify-end w-5/6">
        <div className="">
          <button
            onClick={showModalHandler}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
          >
            New
          </button>
        </div>
      </div>
      <div className="flex justify-center">
        <table className="border-collapse table-auto w-5/6 text-sm">
          <thead>
            <tr>
              <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-gren-400 dark:text-slate-200 text-left">
                Category
              </th>
              <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"></th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-slate-800">
            {cats.map((item) => (
              <tr key={item.id}>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                  {item.name}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400 flex justify-end">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-5"
                    onClick={() => {
                      editCat(item);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                    onClick={() => {
                      deleteCat(item.id);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {showModal && (
          <Modal onClose={hideModalHandler}>
            <FormCat onClose={hideModalHandler} item={editCategory} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default ListCats;

export async function loader({ request, params }) {
  const itemId = params.id;
  const response = await fetch(
    `https://react-http-29978-default-rtdb.firebaseio.com/foods/${itemId}.json`
  );
  if (!response.ok) {
    throw json(
      { message: "Could not fetch details for selected event." },
      { status: 500 }
    );
  } else {
    const resData = await response.json();

    return {
      id: itemId,
      description: resData.description,
      price: resData.price,
      title: resData.title,
      image: resData.image,
    };
  }
}
