import React from "react";

const CatFilter = (props) => {
  
  return (
    <div className="relative rounded-xl overflow-auto">
      <div className="w-5/6 mx-auto shadow-xl min-w-0 dark:bg-slate-800 dark:highlight-white/5">
        <div className="overflow-x-scroll flex">
          <div className="flex-none py-6 px-3 first:pl-6 last:pr-6" key="0">
            <div className="flex flex-col items-center justify-center gap-3">
              <button
                className="px-4 py-2 bg-red-800 hover:bg-red-500 text-white text-sm font-medium rounded-full"
                onClick={() => {
                  props.onSelect(null);
                }}
              >
                All
              </button>
            </div>
          </div>
          {props.cats.map((item) => (
            <div
              className="flex-none py-6 px-3 first:pl-6 last:pr-6"
              key={item.id}
            >
              <div className="flex flex-col items-center justify-center gap-3">
                <button
                  className="px-4 py-2 bg-red-800 hover:bg-orange-500 text-white text-sm font-medium rounded-full"
                  onClick={() => {
                    props.onSelect(item.id);
                  }}
                >
                  {item.name}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CatFilter;
