import React from "react";
import { Form, Link } from "react-router-dom";
import { useAuthStore } from "../../store/auth-store";

const MainNavigation = () => {
  const auth = useAuthStore((state) => state.auth);
  return (
    <nav className="fixed top-0 left-0 z-20 w-full bg-white py-2.5 px-6 sm:px-4">
      <div className="container mx-auto flex max-w-6xl flex-wrap items-center justify-between">
        <span className="self-center whitespace-nowrap text-xl font-semibold text-red-800">
          Menu
        </span>
        <div className="mt-2 sm:mt-0 sm:flex md:order-2">
          {!auth && (
            <Link
              to="/login"
              className="rounde mr-3 hidden border border-red-800 py-1.5 px-6 text-center text-sm font-medium text-red-800 focus:outline-none focus:ring-4 focus:ring-blue-300 md:inline-block rounded-lg"
            >
              Login
            </Link>
          )}
          {auth && auth.token && (
            <Form action="/logout" method="post">
              <button className="rounde mr-3 hidden border border-red-800 py-1.5 px-6 text-center text-sm font-medium text-red-800 focus:outline-none focus:ring-4 focus:ring-blue-300 md:inline-block rounded-lg">
                Logout
              </button>
            </Form>
          )}

          {auth && auth.token && (
            <Link
              to="/admin/newItem"
              className="rounde mr-3 hidden bg-red-800 py-1.5 px-6 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 md:mr-0 md:inline-block rounded-lg"
            >
              Nuevo
            </Link>
          )}

          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 md:hidden"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
          id="navbar-sticky"
        >
          <ul className="mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:text-sm md:font-medium">
            <li>
              <Link
                to="/"
                className="block rounded bg-blue-700 py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 md:text-blue-700"
                aria-current="page"
              >
                Productos
              </Link>
            </li>
            <li>
              <Link
                to="listCats"
                className="block rounded py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-100 md:p-0 md:hover:bg-transparent md:hover:text-blue-700"
              >
                Categorías
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
