import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from "../components/layout/Footer";
import Logo from "../components/layout/Logo";
import MainNavigation from "../components/layout/MainNavigation";
import 'react-toastify/dist/ReactToastify.css';
import { useAuthStore } from "../store/auth-store";

const RootLayout = () => {
  const auth = useAuthStore((state) => state.auth);
  return (
    <>      
      <Logo />
      <Outlet />
      <Footer />       
      <ToastContainer />     
    </>
  );
};

export default RootLayout;
